import { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';

import { dataGridColumns, dataGridPremiumColumns } from '../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
  DataGridUI,
  ButtonUI,
  CheckboxUI,
} from '../Interface';
import { Box, Typography } from '@mui/material';
import SourceListMachineDialog from './SourceListMachineDialog';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    defaultField.isNew = true;
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    await validateField(`sourceListOperation.${values.sourceListOperation.length ?? 0}.operationId`);
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const SourceListOperation = ({ disabled, defaultField, autocompleteOptions, setRefetchTable, selectedValuesState }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});
  const [addModalShow, setAddModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [machineOperation, setMachineOperation] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.sourceListOperation.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    await validateField(`sourceListOperation.${valueIndex}.operationId`);

    if (errors?.sourceListOperation?.[valueIndex]?.message) {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'operationId',
        headerName: 'Név',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.operationId
          );

          return findedOperation?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.operationId
          );
          return (
            <Field
              name={`sourceListOperation.${valueIndex}.operationId`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.operationId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`sourceListOperation.${valueIndex}.operationId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`sourceListOperation.${valueIndex}.operationId`)}
              value={findedOperation}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={autocompleteOptions.operation}
            />
          );
        },
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
      {
        field: 'chk',
        type: 'actions',
        cellClassName: 'actions',
        headerName: 'Lejelentési pont',
        sortable: false,
        getActions: ({ id, row }) => {
          return [<Field name="a" type="checkbox" as={CheckboxUI} fullWidth disabled={disabled} />];
        },
      },
    ]);
  };

  const getDetailPanelContent = useCallback(
    ({ row, params }) => {
      const cols = dataGridColumns([
        {
          field: 'resourceId',
          headerName: 'Erőforrás',
          flex: 1,
          renderCell: (data) => {
            const findedRes = autocompleteOptions.resource.find((r) => r.value === data.row.resourceId);

            return findedRes?.label ?? '';
          },
        },
        {
          field: 'neededQuantity',
          headerName: 'Szükséges mennyiség',
          flex: 1,
        },
        {
          field: 'actions',
          headerName: '',
          flex: 0.4,
          renderCell: (data) => {
            return (
              <>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={() => {
                    const findedLine = row?.sourceListOperationMachine.find((om) => om.id === data.row.id);
                    setMachineOperation(findedLine);
                    setSelectedItem(row);
                    setAddModalShow(true);
                  }}
                  color="inherit"
                  disabled={disabled}
                />
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => {}}
                  color="inherit"
                  disabled={disabled}
                />
              </>
            );
          },
        },
      ]);

      return (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h3" align="center" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            Erőforrás szükséglet
          </Typography>
          {!disabled && !row.isNew && (
            <Box sx={{ mt: 2 }}>
              <ButtonUI
                type="button"
                text="Új erőforrás"
                className="bg-success"
                onClick={() => {
                  setAddModalShow(true);
                  setSelectedItem(row);
                }}
              />
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <DataGridUI
              datagridIdentifier={DataGridIdentifiers.sourceListOperationMachine}
              sx={{ height: 280 }}
              columns={cols}
              rows={row.sourceListOperationMachine ?? []}
              onCellDoubleClick={(_params, event) => {
                event.defaultMuiPrevented = true;
              }}
            />
          </Box>
        </Box>
      );
    },
    [disabled, autocompleteOptions, setAddModalShow, setSelectedItem, setMachineOperation, selectedItem]
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  return (
    <>
      <div className="overflow-x-auto">
        <div className="align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <FieldArray name="sourceListOperation">
              {({ remove, push }) => (
                <DatagridPremiumUi
                  datagridIdentifier={DataGridIdentifiers.sourceListOperation}
                  height={480}
                  columns={columns(setFieldValue, validateField, errors, remove)}
                  rows={values?.sourceListOperation ?? []}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      disabled,
                      push,
                      defaultField,
                      setRowModesModel,
                    },
                  }}
                  onCellDoubleClick={(_params, event) => {
                    event.defaultMuiPrevented = true;
                  }}
                  getDetailPanelHeight={getDetailPanelHeight}
                  getDetailPanelContent={getDetailPanelContent}
                  headerFilters={false}
                  getRowHeight={() => 'auto'}
                />
              )}
            </FieldArray>
          </div>
        </div>
      </div>
      <SourceListMachineDialog
        show={addModalShow}
        onHide={() => {
          setAddModalShow(false);
          setSelectedItem({});
          setMachineOperation({});
        }}
        setRefetchTable={setRefetchTable}
        selectedItem={selectedItem}
        autocompleteOptions={autocompleteOptions}
        machineOperation={machineOperation}
        selectedValuesState={selectedValuesState}
      />
    </>
  );
};

export default SourceListOperation;
