import { useRef } from 'react';
import { Button, DialogActions, Dialog, DialogContent, DialogTitle, Paper } from '@mui/material';
import Draggable from 'react-draggable';

const DialogUI = (props) => {
  const { open, headerContent, children, onClose, headerColor, closeBtn } = props;

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle className="bg-labelColor">
        {headerContent && (
          <h2 className={`${headerColor ? `text-${headerColor}` : 'text-white'} py-1 px-3 rounded`}>{headerContent}</h2>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{closeBtn && <Button onClick={onClose}>Bezárás</Button>}</DialogActions>
    </Dialog>
  );
};

export default DialogUI;
