import React, { useEffect } from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../../Interface';

const ProductionFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteOptions,
  autocompleteData,
  getSourceData,
  calculateLineQty,
}) => {
  useEffect(() => {
    getSourceData(values.articleNumber);
  }, [values.articleNumber]);

  useEffect(() => {
    calculateLineQty();
  }, [values.productionQuantity, values.productionOperation, values.productionItem]);

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-3 md:col-span-1">
        <Field
          type="text"
          name="workingNumber"
          disabled
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Munkaszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.workingNumber} />}
          InputLabelProps={{ shrink: values.workingNumber !== '' }}
          value={values?.workingNumber ?? ''}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          dataset={autocompleteOptions.project}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="articleNumber"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cikk" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.articleNumber} />}
          onChange={async (_e, newVal) => {
            setFieldValue('articleNumber', newVal?.value ?? null);
            await getSourceData(newVal?.value);

            const findedItem = autocompleteData.items.find((i) => i.itemCode === newVal?.value);
            setFieldValue('me', findedItem?.quantityUnitId ?? null);
            setFieldValue('articleName', findedItem?.itemName ?? null);
            setFieldValue('productionOperation', []);
            await validateField('articleNumber');
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.articleNumber !== '' }}
          selectedValue={values.articleNumber}
          dataset={autocompleteOptions?.items}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="number"
          name="productionQuantity"
          //as={InputUI}
          component={InputUI}
          fullWidth
          label={<FormLabelUI text="Gyártandó mennyiség" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.productionQuantity} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.productionQuantity !== '' }}
          onChange={async (e) => {
            await setFieldValue('productionQuantity', e.target?.value ? parseFloat(e.target?.value) : null);
            await validateField('productionQuantity');
          }}
          value={values.productionQuantity}
        />
      </div>

      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="me"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="ME" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.me} />}
          onChange={(_e, newVal) => {
            setFieldValue('me', newVal?.value ?? null).then(() => {
              validateField('me');
            });
          }}
          disabled
          InputLabelProps={{ shrink: values.me !== '' }}
          selectedValue={values.me}
          selectedLabelValue={selectedValues?.me}
          dataset={autocompleteOptions.quantityUnit}
        />
      </div>

      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="GY. tervezett kezdete" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="GY. tervezett vége" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gyártás státusza" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          dataset={autocompleteOptions.status}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="priorityId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gyártás priorítása" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.priorityId} />}
          onChange={(_e, newVal) => {
            setFieldValue('priorityId', newVal?.value ?? null).then(() => {
              validateField('priorityId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.priorityId !== '' }}
          selectedValue={values.priorityId}
          selectedLabelValue={selectedValues?.priority?.itemName}
          dataset={autocompleteOptions.priority}
        />
      </div>
      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="text"
          name="customerOrdNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Vevői rendelés szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerOrdNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerOrdNumber !== '' && values.customerOrdNumber !== null }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="companyId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Vevő neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyId', newVal?.value ?? null).then(() => {
              validateField('companyId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyId !== '' }}
          selectedValue={values.companyId}
          selectedLabelValue={selectedValues?.company?.companyName}
          dataset={autocompleteOptions.company}
        />
      </div>
      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="text"
          name="customerOrdId"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Vevői rendelés azonosító" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerOrdId} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerOrdId !== '' && values.customerOrdId !== null }}
        />
      </div>
      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="text"
          name="customerOrdLine"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Vevői rendelés sora" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerOrdLine} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerOrdLine !== '' && values.customerOrdLine !== null }}
        />
      </div>
      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="text"
          name="reference"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Vevőhivatkozási szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.reference} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.reference !== '' && values.reference !== null }}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="finishedItemCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Késztermék" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.finishedItemCode} />}
          onChange={async (_e, newVal) => {
            setFieldValue('finishedItemCode', newVal?.value ?? null);

            await validateField('finishedItemCode');
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.finishedItemCode !== '' }}
          selectedValue={values.finishedItemCode}
          dataset={autocompleteOptions?.items}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Tulajdonos" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          disabled
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.userName}
          dataset={autocompleteOptions.user}
        />
      </div>
    </div>
  );
};

export default ProductionFields;
