import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import correctionService from '../../service/correction.service';
import { ColorPicker } from '../../config/ColorPicker';
import useDataGridDynamicStyles from '../../hooks/useDataGridDynamicStyles';

const CorrectionReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  dynProps,
}) => {
  useDataGridDynamicStyles(ColorPicker);
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'prefixedId', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    correctionService.getCorrections(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'prefixedId',
        type: 'string',
        headerName: 'Sorszám',
      },

      {
        field: 'area',
        type: 'string',
        headerName: 'Terület',
      },

      {
        field: 'origin',
        type: 'string',
        headerName: 'Eredet',
      },

      {
        field: 'error',
        type: 'string',
        headerName: 'Hiba',
      },

      {
        field: 'causeOfError',
        type: 'string',
        headerName: 'Hiba oka',
      },

      {
        field: 'action',
        type: 'string',
        headerName: 'Intézkedés',
      },

      {
        field: 'responsibleId',
        type: 'singleSelect',
        headerName: 'Felelős',
        renderCell: (data) => {
          return data.row.user?.userName;
        },
        valueOptions: autocompleteOptions.users,
      },

      {
        field: 'deadline',
        type: 'date',
        headerName: 'Határidő',
        valueGetter: (_data, row) => {
          return new Date(row.date);
        },
      },

      {
        field: 'documentation',
        type: 'string',
        headerName: 'Dokumentáció',
      },

      {
        field: 'evaluation',
        type: 'string',
        headerName: 'Értékelés',
      },
      ...generateColumnsFromDynProps(dynProps ?? []),
    ]);
  }, [autocompleteOptions, dynProps]);

  return (
    <>
      <DatagridPremiumUi
        datagridIdentifier={DataGridIdentifiers.CorrectionList}
        datagridInitialState={initialState}
        dataLoader={getList}
        rows={reports.rows}
        refetch={{ refetchTable, setRefetchTable }}
        rowCount={reports.rowCount}
        columns={[...columns]}
        onRowClick={(rowData) => {
          if (!viewField) return;
          setSelectedValues(rowData.row);
        }}
        paginationAndfilterMode="server"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick={!viewField}
      />
    </>
  );
};

export default CorrectionReports;
