import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, SelectUI, AutoCompleteSelectUI } from '../Interface';
import { PriceFieldType } from '../../config/priceList';
import { AreaTypeList, GroupTypeList } from '../../config/companyGroup';

export const CompanyGroupFields = ({ viewField, autoCompleteOptions, selectedValues }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-1">
        <Field
          name="groupType"
          component={SelectUI}
          fullWidth
          option={GroupTypeList}
          value={values.groupType ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('groupType', value ?? null).then(() => {
              validateField('groupType');
            });
          }}
          label={<FormLabelUI text="Csoportosítás típusa" />}
          helperText={<FormErrorUI message={errors.groupType} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.diregroupTypection !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Csoport név" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="priceListId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Árlista" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.priceListId} />}
          onChange={(_e, newVal) => {
            setFieldValue('priceListId', newVal?.value ?? null).then(() => {
              validateField('priceListId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.priceListId !== '' }}
          selectedValue={values.priceListId}
          dataset={autoCompleteOptions.priceList}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="paymentMethodId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Fizetési mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.paymentMethodId} />}
          onChange={(_e, newVal) => {
            setFieldValue('paymentMethodId', newVal?.value ?? null).then(() => {
              validateField('paymentMethodId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.paymentMethodId !== '' }}
          selectedValue={values.paymentMethodId}
          dataset={autoCompleteOptions.paymentMethod}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="currencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Pénznem" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.currencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('currencyCode', newVal?.value ?? null).then(() => {
              validateField('currencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.currencyCode !== '' }}
          selectedValue={values.currencyCode}
          dataset={autoCompleteOptions.currency}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="vatCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Áfakulcs" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.vatCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('vatCode', newVal?.value ?? null).then(() => {
              validateField('vatCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.vatCode !== '' }}
          selectedValue={values.vatCode}
          dataset={autoCompleteOptions.vatCodes}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="area"
          component={SelectUI}
          fullWidth
          option={AreaTypeList}
          value={values.area ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('area', value ?? null).then(() => {
              validateField('area');
            });
          }}
          label={<FormLabelUI text="Terület" />}
          helperText={<FormErrorUI message={errors.area} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.area !== '' }}
        />
      </div>
    </div>
  );
};
