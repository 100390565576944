import React from 'react';
import { Field } from 'formik';

import { AutoCompleteSelectUI, CheckboxUI, FormErrorUI, FormLabelUI, InputUI, SelectUI } from '../../Interface';
import { FieldType } from '../../../config/DocumentType';

const DocumentTypeFields = ({
  viewField,
  values,
  validateField,
  setFieldValue,
  errors,
  autoCompleteOptions,
  selectedValues,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5 items-center">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="name"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Bizonylattípus neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="prefix"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Prefix" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.prefix} />}
          InputLabelProps={{ shrink: values.prefix !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="direction"
          component={SelectUI}
          fullWidth
          option={FieldType}
          value={values.direction ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('direction', value ?? null).then(() => {
              validateField('direction');
            });
          }}
          label={<FormLabelUI text="Irány" />}
          helperText={<FormErrorUI message={errors.direction} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.direction !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1 pt-3">
        <Field
          name="warehouseManagement"
          as={CheckboxUI}
          fullWidth
          checked={values.warehouseManagement ?? false}
          variant="standard"
          onChange={(e) => {
            setFieldValue('warehouseManagement', e.target.checked ?? null).then(() => {
              validateField('warehouseManagement');
            });
          }}
          label={<FormLabelUI text="Készletkezelés" />}
          helperText={<FormErrorUI message={errors.warehouseManagement} />}
          disabled={viewField}
        />
      </div>
      <div className={`${values.warehouseManagement ? 'col-span-4 md:col-span-1' : 'hidden'}`}>
        <Field
          type="autocomplete"
          name="warehouseCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Raktár" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.warehouseCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('warehouseCode', newVal?.value ?? null).then(() => {
              validateField('warehouseCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.warehouseCode !== '' }}
          selectedValue={values.warehouseCode}
          selectedLabelValue={values?.warehouseCode}
          dataset={autoCompleteOptions.warehouse}
        />
      </div>
    </div>
  );
};

export default DocumentTypeFields;
