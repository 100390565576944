import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../../Interface';
import productionService from '../../../service/production.service';
import dayjs from 'dayjs';

export default function ProductionReports({ productionState }) {
  const {
    setSelectedValues,
    viewField,
    operatorsDisabled,
    setRefetchTable,
    rowSelectionModel,
    setRowSelectionModel,
    refetchTable,
    autocompleteOptions,
  } = productionState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'workingNumber', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    productionService.getProductions(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'workingNumber',
      headerName: 'Munkaszám',
      type: 'string',
    },
    {
      field: 'customerOrdNumber',
      headerName: 'Vevővi rendelés szám',
      type: 'string',
    },
    {
      field: 'articleNumber',
      headerName: 'Cikkszám',
      type: 'string',
    },
    {
      field: 'articleName',
      headerName: 'Cikknév',
      type: 'string',
    },
    {
      field: 'productionQuantity',
      headerName: 'Mennyiség',
      type: 'string',
    },
    {
      field: 'me',
      headerName: 'Me.',
      type: 'singleSelect',
      sortable: false,
      renderCell: (params) => {
        return params.row.quantityUnit?.quantityUnitName;
      },
      valueOptions: autocompleteOptions.quantityUnit,
    },
    {
      field: 'startDate',
      type: 'date',
      headerName: 'Kezdő időpont',
      valueGetter: (_data, row) => {
        return new Date(row.startDate);
      },
      renderCell: (data) => {
        return dayjs(data.row.startDate).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      field: 'endDate',
      type: 'date',
      headerName: 'Befejező időpont',
      valueGetter: (_data, row) => {
        return new Date(row.endDate);
      },
      renderCell: (data) => {
        return dayjs(data.row.endDate).format('YYYY.MM.DD HH:mm');
      },
    },
    {
      field: 'projectId',
      headerName: 'Projekt',
      type: 'singleSelect',
      sortable: false,
      renderCell: (data) => {
        return data.row.project?.projectName;
      },
      valueOptions: autocompleteOptions.project,
    },
    {
      field: 'statusId',
      headerName: 'Státusz',
      type: 'singleSelect',
      sortable: false,
      renderCell: (params) => {
        return params.row.status?.itemName;
      },
      valueOptions: autocompleteOptions.status,
    },
    {
      field: 'priorityId',
      headerName: 'Prioritás',
      type: 'singleSelect',
      sortable: false,
      renderCell: (params) => {
        return params.row.priority?.itemName;
      },
      valueOptions: autocompleteOptions.priority,
    },
    {
      field: 'companyId',
      headerName: 'Vevő neve',
      type: 'singleSelect',
      sortable: false,
      renderCell: (params) => {
        return params.row.company?.companyName;
      },
      valueOptions: autocompleteOptions.company,
    },
    {
      field: 'customerOrdId',
      headerName: 'VERE id',
      type: 'string',
    },
    {
      field: 'customerOrdLine',
      headerName: 'VERE sor',
      type: 'string',
    },
    {
      field: 'reference',
      headerName: 'Vevőhiv.',
      type: 'string',
    },
    {
      field: 'userId',
      headerName: 'Tulajdonos',
      type: 'singleSelect',
      sortable: false,
      renderCell: (params) => {
        return params.row.user?.userName;
      },
      valueOptions: autocompleteOptions.user,
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.ProductionReports}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
}
