import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import operationService from '../../service/operation.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import useMenus from '../../context/MenuContext';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import FormFields from './FormFields';
import OperationReports from './OperationReports';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const OperationForm = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = { operationName: '', resourceOneId: null };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    resourceOne: [],
  });

  useEffect(() => {
    setInitValues(defaultValues);
    getMenus();
    initDefaultValues();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('operation');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const validationSchema = Yup.object().shape({
    operationName: Yup.string().required(),
    resourceOneId: Yup.string().nullable(),
  });

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    masterDataService
      .resourceOneToForm()
      .then((data) => {
        const aData = {
          resourceOne: data,
        };

        const acOptions = {
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const { operationName, resourceOneId } = selectedValues;

    setInitValues({
      operationName,
      resourceOneId,
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    const insertObject = { ...values };

    operationService
      .createOperation(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues(data);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
    };

    operationService.updateOperation(updData, selectedValues.operationId).then((_operation) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    operationService.deleteOperation(selectedValues.operationId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  autoCompleteOptions={autoCompleteOptions}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <OperationReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
        autoCompleteOptions={autoCompleteOptions}
      />
    </div>
  );
};
