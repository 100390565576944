import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';

const FormFields = ({ viewField, autoCompleteOptions, selectedValues }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="operationName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Művelet neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.operationName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.operationName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="resourceOneId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gép" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.resourceOneId} />}
          onChange={(_e, newVal) => {
            setFieldValue('resourceOneId', newVal?.value ?? null).then(() => {
              validateField('resourceOneId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.resourceOneId !== '' }}
          selectedValue={values.resourceOneId}
          selectedLabelValue={selectedValues?.resourceOne?.name}
          dataset={autoCompleteOptions.resourceOne}
        />
      </div>
    </div>
  );
};

export default FormFields;
