import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, SelectUI, AutoCompleteSelectUI } from '../Interface';
import { FieldType } from '../../config/DocumentType';
import { PriceFieldType } from '../../config/priceList';

export const PriceListFields = ({ viewField, autoCompleteOptions }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Árlista neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="currencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Árlista pénzneme" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.currencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('currencyCode', newVal?.value ?? null).then(() => {
              validateField('currencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.currencyCode !== '' }}
          selectedValue={values.currencyCode}
          dataset={autoCompleteOptions.currency}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="direction"
          component={SelectUI}
          fullWidth
          option={FieldType}
          value={values.direction ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('direction', value ?? null).then(() => {
              validateField('direction');
            });
          }}
          label={<FormLabelUI text="Irány" />}
          helperText={<FormErrorUI message={errors.direction} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.direction !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="priceForm"
          component={SelectUI}
          fullWidth
          option={PriceFieldType}
          value={values.priceForm ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('priceForm', value ?? null).then(() => {
              validateField('priceForm');
            });
          }}
          label={<FormLabelUI text="Árforma" />}
          helperText={<FormErrorUI message={errors.priceForm} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.priceForm !== '' }}
        />
      </div>
    </div>
  );
};
