import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { huHU } from '@mui/x-data-grid/locales';
import { isEqual } from 'lodash';
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridLogicOperator,
  gridColumnFieldsSelector,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';

import { datagridLangHunCustomized } from '../../../config/datagridLangHunCustomized';
import useSaveView from '../../../hooks/useSaveView';
import useView from '../../../hooks/useView';
import { getDataGridStyles } from '../Datagrid/dataGridStyles';
import { DataGridIdentifiers } from '../Datagrid/DataGridIdentifiers';
import useUsers from '../../../context/UsersContext';

const DataGridPremiumUI = ({
  datagridIdentifier,
  dataLoader,
  paginationAndfilterMode = 'client',
  datagridInitialState,
  refetch = { refetchTable: false, setRefetchTable: () => {} },
  toolbar,
  gridExport,
  ...props
}) => {
  const apiRef = useGridApiRef();
  const { refetchTable, setRefetchTable } = refetch;
  const parentNode = document.body.parentNode;
  const height = parentNode.clientHeight * 0.85;

  const { getUser, user } = useUsers();
  const [isLoading, setisLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [queryOptions, setQueryOptions] = useState({
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [],
    },
  });

  const [initialState, setInitialState] = useState({});
  const saveView = useSaveView(user);

  const useColumnsState = (apiRef, columns) => {
    const [widths, setWidths] = React.useState({});
    const [orderedFields, setOrderedFields] = React.useState(() => columns.map((column) => column.field));

    const onColumnWidthChange = React.useCallback(
      ({ colDef, width }) => {
        setWidths((prev) => ({ ...prev, [colDef.field]: width }));
        const currentState = apiRef.current.exportState();
        if (Object.keys(user).length > 0) saveView('dataGrid', { [datagridIdentifier]: currentState });
      },
      [setWidths]
    );

    const onColumnOrderChange = React.useCallback(() => {
      setOrderedFields(gridColumnFieldsSelector(apiRef));
      const currentState = apiRef.current.exportState();
      if (Object.keys(user).lengt > 0) saveView('dataGrid', { [datagridIdentifier]: currentState });
    }, [apiRef, setOrderedFields]);

    const computedColumns = React.useMemo(
      () =>
        orderedFields.reduce((acc, field) => {
          const column = columns.find((col) => col.field === field);
          if (!column) {
            return acc;
          }
          if (widths[field]) {
            acc.push({
              ...column,
              width: widths[field],
            });
            return acc;
          }
          acc.push(column);
          return acc;
        }, []),
      [columns, widths, orderedFields]
    );

    return { columns: computedColumns, onColumnWidthChange, onColumnOrderChange };
  };

  const columnsState = useColumnsState(apiRef, props.columns);

  const handleEvent = (_params, _event, _details) => {
    if (Object.keys(_params).length > 0) {
      const currentState = apiRef.current.exportState();
      setInitialState(currentState);
      if (Object.keys(user).length > 0) saveView('dataGrid', { [datagridIdentifier]: currentState });
    }
  };

  const loader = async (tableState) => {
    setisLoading(true);
    // Ha a pageSize -1 (azaz "Összes"), akkor ne alkalmazzunk korlátozást, kitörli a paginationModelt.
    if (tableState.pagination.paginationModel?.pageSize === -1) {
      delete tableState.pagination.paginationModel;
    }
    await dataLoader(tableState);
    setisLoading(false);
  };

  useEffect(() => {
    if (Object.keys(apiRef?.current).length > 0) {
      apiRef.current.subscribeEvent('aggregationModelChange', handleEvent);
      apiRef.current.subscribeEvent('columnVisibilityModelChange', handleEvent);
      apiRef.current.subscribeEvent('columnOrderChange', handleEvent);
      apiRef.current.subscribeEvent('columnWidthChange', handleEvent);
      apiRef.current.subscribeEvent('filterModelChange', handleEvent);
      apiRef.current.subscribeEvent('paginationModelChange', handleEvent);
      apiRef.current.subscribeEvent('rowGroupingModelChange', handleEvent);
      apiRef.current.subscribeEvent('sortModelChange', handleEvent);
      apiRef.current.subscribeEvent('onDensityChange', handleEvent);
      //apiRef.current.subscribeEvent('rowOrderChange', handleEvent);
      //apiRef.current.subscribeEvent('rowCountChange', handleEvent);
    }
  }, [apiRef.current]);

  useEffect(() => {
    const savedDatagridState = user.views?.dataGrid?.[datagridIdentifier];
    if (savedDatagridState && Object.keys(apiRef?.current).length > 0 && user && isInitialLoad) {
      const options = {
        filter: savedDatagridState?.filter ?? datagridInitialState?.filter,
        pagination: savedDatagridState?.pagination ?? datagridInitialState?.pagination,
        sorting: savedDatagridState?.sorting ?? datagridInitialState?.sorting,
      };
      setInitialState(savedDatagridState);
      apiRef.current.restoreState(savedDatagridState);
      apiRef.current.hidePreferences();

      setQueryOptions(options);

      if (paginationAndfilterMode === 'server') {
        loader(options);
      }

      setIsInitialLoad(false);
    }
    if (!savedDatagridState && Object.keys(apiRef?.current).length > 0 && user && isInitialLoad) {
      const options = {
        filter: datagridInitialState?.filter,
        pagination: datagridInitialState?.pagination,
        sorting: datagridInitialState?.sorting,
      };
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }

      //setIsInitialLoad(false); de ezt miért raktam ide?
    }
  }, [datagridIdentifier, user]);

  useEffect(() => {
    if (!isInitialLoad && refetchTable) {
      loader(queryOptions);
      setRefetchTable(false);
    }
  }, [refetchTable]);

  if (!datagridIdentifier) {
    console.error('datagridIdentifier is required but was not provided.');
    return null; // Prevent the component from rendering if the identifier is not provided
  }
  if (!paginationAndfilterMode) {
    console.error('paginationAndfilterMode is required but was not provided.');
    return null; // Prevent the component from rendering if the paginationAndFilterMode is not provided
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {gridExport && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  };

  const onFilterModelChange = (filterModel) => {
    if (!isInitialLoad) {
      const filteredItems = filterModel.items.filter((item) => {
        if (item.operator === 'isEmpty' || item.operator === 'isNotEmpty') {
          return true;
        }
        if (item.operator === 'isAnyOf') {
          return Array.isArray(item.value) && item.value.length > 0;
        }
        return item.value !== undefined && item.value !== null;
      });
      const newModel = { ...filterModel, items: filteredItems };

      const options = { ...queryOptions, filter: { filterModel: newModel } };

      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  const onPaginationModelChange = (paginationModel) => {
    if (!isInitialLoad) {
      let options;
      if (paginationModel.pageSize === -1) {
        // Ha "Összes", akkor ne küldjünk pagination modelt a backendnek
        options = { ...queryOptions, pagination: {} };
      } else {
        options = { ...queryOptions, pagination: { paginationModel } };
      }
      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  const onSortModelChange = (sortModel) => {
    if (!isInitialLoad) {
      const options = { ...queryOptions, sorting: { sortModel } };
      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  return (
    <DataGridPremium
      {...props}
      pagination
      columns={columnsState.columns}
      onColumnWidthChange={columnsState.onColumnWidthChange}
      onColumnOrderChange={columnsState.onColumnOrderChange}
      apiRef={apiRef}
      initialState={{ ...datagridInitialState, ...initialState }}
      rowHeight={45}
      sx={getDataGridStyles({ height: props.height ?? height })}
      slots={{ toolbar: CustomToolbar, ...props.slots }}
      slotProps={{
        ...props.slotProps,
        columnsPanel: {
          sx: {
            '& .MuiDataGrid-panelFooter button:first-child': {
              display: 'none',
            },
          },
        },
        filterPanel: {
          logicOperators: [GridLogicOperator.And],
        },
      }}
      componentsProps={{
        columnMenu: {
          hide: true, // Tiltja az oszlop panel megnyitását
        },
      }}
      localeText={{ ...huHU.components.MuiDataGrid.defaultProps.localeText, ...datagridLangHunCustomized }}
      disableSelectionOnClick
      headerFilters={props.headerFilters ?? true}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      paginationMode={paginationAndfilterMode}
      sortingMode={paginationAndfilterMode}
      filterMode={paginationAndfilterMode}
      loading={isLoading}
      pageSizeOptions={[25, 50, 100].concat(gridExport ? [{ value: -1, label: 'Összes' }] : [])}
    />
  );
};

DataGridPremiumUI.propTypes = {
  datagridIdentifier: PropTypes.oneOf(Object.values(DataGridIdentifiers)).isRequired,
  paginationAndfilterMode: PropTypes.oneOf(['server', 'client']).isRequired,
};

export default DataGridPremiumUI;
